<template>
  <div class="wrapper" id="payreg_add">
    <ctheader></ctheader>
    <div ref="payreg_add" class="payreg_add">
      <div class="title">基础信息</div>
      <el-form ref="form" :model="form" label-width="220px" size="small">
        <el-row>
          <el-col :span="12">
            <el-form-item label="商户编号：" prop="mer_no">
              <el-input v-model="form.mer_no" style="width:300px"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="商户名称：" prop="mer_name">
              <el-input v-model="form.mer_name" style="width:300px"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="商户类型：" prop="mer_type">
              <el-radio-group v-model="form.mer_type">
                <el-radio :label="1">个人商户</el-radio>
                <el-radio :label="2">企业</el-radio>
                <el-radio :label="3">个体工商户</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="商户种类：" prop="mer_sort">
              <el-radio-group v-model="form.mer_sort">
                <el-radio :label="1">普通类</el-radio>
                <el-radio :label="9">其他</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="法人/经营者姓名" prop="contact_name">
              <el-input
                v-model="form.contact_name"
                style="width:300px"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="法人/经营者证件类型">
              <el-radio-group v-model="form.certificate_type">
                <el-radio label="I">身份证</el-radio>
                <el-radio label="P">护照</el-radio>
                <el-radio label="G">军官证</el-radio>
                <el-radio label="U">其他证件</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="法人/经营者证件编号">
              <el-input
                v-model="form.certificate_no"
                style="width:300px"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="个人工商户/企业名称">
              <el-input
                v-model="form.company_name"
                style="width:300px"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="12">
            <el-form-item label="个体工商户/企业证件类型">
              <el-radio-group v-model="form.company_cert_type">
                <el-radio label="U">营业执照</el-radio>
                <el-radio label="Y">组织机构代码证</el-radio>
                <el-radio label="V">统一社会信用代码</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="个体工商户/企业证件类型编号">
              <el-input
                v-model="form.company_cert_no"
                style="width:300px"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="银行账户户名">
              <el-input
                v-model="form.account_name"
                style="width:300px"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="银行账户">
              <el-input
                v-model="form.mobile_phone"
                style="width:300px"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="银行账户类型">
              <el-radio-group v-model="form.account_type">
                <el-radio label="1">借记卡</el-radio>
                <el-radio label="2">企业户</el-radio>
                <el-radio label="3">二类户</el-radio>
                <el-radio label="4">三类户</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="实际经营地址">
              <el-input v-model="form.address" style="width:300px"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="备注">
              <el-input v-model="form.remark" style="width:300px"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="商户负责人联系电话">
              <el-input
                v-model="form.mer_mobile_phone"
                style="width:300px"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-divider></el-divider>
      <div class="cert">
        <div class="title">证件</div>
        <div class="list">
          <el-card class="box-card" shadow="never">
            <div slot="header" class="clearfix">
              <span>
                营业执照照片
              </span>
            </div>
            <div>
              <el-upload
                action="/api/payment/permer"
                :data="{
                  action: 'cert.upload',
                  mer_id: mer_id,
                  flag: 1,
                }"
                :on-success="handleSuccess"
                :on-remove="handleRemove"
                :file-list="fileList"
              >
                <el-button type="text" size="mini">上传</el-button>
              </el-upload>
              <el-button
                type="text"
                size="mini"
                @click="open(certList.filter((c) => c.flag == 1)[0].cert_file)"
                style="margin-top:10px"
                v-if="certList.length > 0 && certList[0].cert_file"
              >
                查看
              </el-button>
            </div>
          </el-card>
          <el-card class="box-card" shadow="never">
            <div slot="header" class="clearfix">
              <span>
                法人/负责人身份证正反面照片
              </span>
            </div>
            <div>
              <el-upload
                action="/api/payment/permer"
                :data="{
                  action: 'cert.upload',
                  mer_id: mer_id,
                  flag: 2,
                }"
                :on-success="handleSuccess"
                :on-remove="handleRemove"
                :file-list="fileList"
              >
                <el-button type="text" size="mini">上传</el-button>
              </el-upload>
              <el-button
                type="text"
                size="mini"
                @click="open(certList.filter((c) => c.flag == 2)[0].cert_file)"
                style="margin-top:10px"
                v-if="certList.length > 1 && certList[1].cert_file"
              >
                查看
              </el-button>
            </div>
          </el-card>
          <el-card class="box-card" shadow="never">
            <div slot="header" class="clearfix">
              <span>
                辅助证明材料
              </span>
            </div>
            <div>
              <el-upload
                action="/api/payment/permer"
                :data="{
                  action: 'cert.upload',
                  mer_id: mer_id,
                  flag: 3,
                }"
                :on-success="handleSuccess"
                :on-remove="handleRemove"
                :file-list="fileList"
              >
                <el-button type="text" size="mini">上传</el-button>
              </el-upload>

              <el-button
                type="text"
                size="mini"
                style="margin-top:10px"
                @click="open(certList.filter((c) => c.flag == 3)[0].cert_file)"
                v-if="certList.length > 2 && certList[2].cert_file"
              >
                查看
              </el-button>
            </div>
          </el-card>
        </div>
      </div>
      <el-button type="primary" size="small" @click="createAccount">
        创建分账分组
      </el-button>
      <el-button type="primary" size="small" @click="detailAccount">
        查看分账分组
      </el-button>
      <el-table
        :data="tableData"
        style="width: 100%;margin-top:20px"
        :header-cell-style="getRowClass"
      >
        <el-table-column label="银行">
          <template slot-scope="scope">
            {{ scope.row.mode_id == 601 ? "农行" : "其他" }}
          </template>
        </el-table-column>
        <el-table-column prop="mer_no" label="账户编号">
          <template slot-scope="scope">
            {{ scope.row.mer_no }}
          </template>
        </el-table-column>
        <el-table-column label="审核状态">
          <template slot-scope="scope" v-if="scope.row.review_status">
            {{
              scope.row.review_status == 0
                ? "待申请"
                : scope.row.review_status == 1
                ? "账户待确认"
                : scope.row.review_status == 2
                ? "开户资料待提交"
                : scope.row.review_status == 3
                ? "开户资料审核中"
                : scope.row.review_status == 4
                ? "已验证，审核通过"
                : scope.row.review_status == 5
                ? "账户已关闭"
                : scope.row.review_status == 6
                ? "审核拒绝"
                : "驳回"
            }}
          </template>
        </el-table-column>
        <el-table-column label="签约号" prop="submer_sign_no">
          <template slot-scope="scope" v-if="scope.row.submer_sign_no">
            {{ scope.row.submer_sign_no }}
          </template>
        </el-table-column>
        <el-table-column label="申请单号" prop="serial_no">
          <template slot-scope="scope" v-if="scope.row.serial_no">
            {{ scope.row.serial_no }}
          </template>
        </el-table-column>
        <el-table-column label="创建时间" prop="created_at">
          <template slot-scope="scope" v-if="scope.row.created_at">
            {{ scope.row.created_at | filterSecond }}
          </template>
        </el-table-column>
        <el-table-column label="更新时间" prop="updated_at">
          <template slot-scope="scope" v-if="scope.row.updated_at">
            {{ scope.row.updated_at | filterSecond }}
          </template>
        </el-table-column>
        <el-table-column
          label="操作"
          prop="updated_at"
          fixed="right"
          width="200"
        >
          <template slot-scope="scope">
            <el-button
              @click="register(scope.row.mode_id)"
              size="mini"
              type="text"
              v-if="!scope.row.mer_no"
            >
              开户
            </el-button>
            <el-button
              v-if="scope.row.review_status == 1"
              @click="verify(scope.row.mode_id)"
              size="mini"
              type="text"
            >
              验证
            </el-button>
            <el-button
              v-if="scope.row.review_status == 2"
              @click="upload(scope.row.mode_id)"
              size="mini"
              type="text"
            >
              上传资料
            </el-button>
            <el-button
              v-if="scope.row.mer_no"
              @click="query(scope.row.mode_id)"
              size="mini"
              type="text"
            >
              状态查询
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-dialog title="信息校验" :visible.sync="verifyDialog" @close="close">
        <el-form :model="verifyForm" size="small" label-width="80px">
          <el-form-item label="验资金额">
            <el-input
              v-model="verifyForm.verify_amount"
              autocomplete="off"
              style="width:300px"
            ></el-input>
          </el-form-item>
          <el-form-item label="验证码">
            <el-input
              v-model="verifyForm.verify_code"
              autocomplete="off"
              style="width:300px"
            ></el-input>
            <el-button
              type="primary"
              @click="sendCode"
              size="small"
              style="background: #257873;
          border-color: #257873;
          margin-left:8px
          "
            >
              发送验证码
            </el-button>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button
            @click="verifyDialog = false"
            size="small"
            type="primary"
            plain
          >
            取 消
          </el-button>
          <el-button
            type="primary"
            @click="confirmVerify"
            size="small"
            class="confirnBtn"
          >
            确 定
          </el-button>
        </div>
      </el-dialog>
      <el-drawer
        title="创建分组"
        :visible.sync="accountDialog"
        @close="close"
        direction="rtl"
        size="50%"
      >
        <el-input
          v-model="group_name"
          size="small"
          placeholder="请输入分组名称"
          style="width:300px;margin-bottom:12px"
        />
        <br />
        <el-select
          v-model="group_mer_id"
          multiple
          placeholder="请选择"
          size="small"
          @change="groupChange"
          style="width:300px;margin-bottom:12px"
        >
          <el-option
            v-for="item in mer_arr"
            :key="item.mer_id"
            :label="item.mer_no"
            :value="item.mer_id"
          >
          </el-option>
        </el-select>
        <el-table :data="accountData" :header-cell-style="getRowClass">
          <el-table-column label="银行">
            <template slot-scope="scope">
              {{ scope.row.mode_id == 601 ? "农行" : "其他" }}
            </template>
          </el-table-column>
          <el-table-column label="商户号" prop="mer_no"> </el-table-column>
          <el-table-column label="分账比例">
            <template slot-scope="scope">
              <el-input-number
                v-model="scope.row.split_ratio"
                size="small"
                :max="100000"
                :precision="0"
              ></el-input-number>
            </template>
          </el-table-column>
        </el-table>
        <div class="demo-drawer__footer">
          <el-button
            @click="accountDialog = false"
            size="small"
            type="primary"
            plain
          >
            取 消
          </el-button>
          <el-button
            type="primary"
            @click="confirmAccount"
            size="small"
            class="confirnBtn"
          >
            确 定
          </el-button>
        </div>
      </el-drawer>
      <el-drawer
        title="查看分组"
        :visible.sync="verifyDialog1"
        size="50%"
        @close="close"
      >
        <div style="overflow:auto;height:800px">
          <el-table :data="accountData1" :header-cell-style="getRowClass">
            <el-table-column label="银行">
              <template slot-scope="scope">
                {{ scope.row.mode_id == 601 ? "农行" : "其他" }}
              </template>
            </el-table-column>
            <el-table-column label="分组名称" prop="group_name">
            </el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-button @click="edit(scope.row)" size="small" type="text">
                  编辑
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-drawer
            title="编辑分组"
            :append-to-body="true"
            :visible.sync="innerDrawer"
            size="40%"
            class="drawer"
            @close="close"
          >
            <div style="padding:24px">
              <el-input
                v-model="group_name"
                size="small"
                placeholder="请输入分组名称"
                style="width:300px;margin-bottom:12px"
              />
              <br />
              <el-select
                v-model="group_mer_id"
                multiple
                placeholder="请选择"
                size="small"
                @change="groupChange"
                style="width:300px;margin-bottom:12px"
              >
                <el-option
                  v-for="item in mer_arr"
                  :key="item.mer_id"
                  :label="item.mer_no"
                  :value="item.mer_id"
                >
                </el-option>
              </el-select>
              <el-table :data="accountData" :header-cell-style="getRowClass">
                <el-table-column label="银行">
                  <template slot-scope="scope">
                    {{ scope.row.mode_id == 601 ? "农行" : "其他" }}
                  </template>
                </el-table-column>
                <el-table-column label="商户编号" prop="mer_no">
                </el-table-column>
                <el-table-column label="分账比例">
                  <template slot-scope="scope">
                    <el-input-number
                      v-model="scope.row.split_ratio"
                      size="small"
                      :max="100000"
                      :precision="0"
                    ></el-input-number>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div class="demo-drawer__footer">
              <el-button
                @click="innerDrawer = false"
                size="small"
                type="primary"
                plain
              >
                取 消
              </el-button>
              <el-button
                type="primary"
                @click="updateAccount"
                size="small"
                class="confirnBtn"
              >
                确 定
              </el-button>
            </div>
          </el-drawer>
        </div>
      </el-drawer>
    </div>
  </div>
</template>

<script>
import ctheader from "../../components/ctheader";
import { apiPost } from "@/modules/utils";
import APIs from "@/modules/APIs";
// import pagination from "../../components/pagination";
import { mapActions } from "vuex";
import router from "../../router";
import * as URL from "../../modules/URLs";
import moment from "moment";

const actions = mapActions(["setAlert"]);

export default {
  components: { ctheader },
  data() {
    return {
      form: {},
      verifyForm: {
        action: "info.audit",
      },
      certList: [],
      tableData: [],
      mer_id: "",
      fileList: [],
      verifyDialog: false,
      accountDialog: false,
      accountData: [],
      group_name: "",
      accountType: "add",
      show: false,
      title: "创建分组",
      mer_arr: [],
      group_mer_id: [],
      verifyDialog1: false,
      accountData1: [],
      innerDrawer: false,
      group_id: "",
    };
  },
  filters: {
    filterSecond(num) {
      return moment.unix(num).format("YYYY-MM-DD HH:mm:ss");
    },
  },
  mounted() {
    console.log(window.location.hostname);
    if (this.$route.params.id) {
      this.getPaymentInfo();
    }
  },
  methods: {
    ...actions,
    getPaymentInfo() {
      let $this = this;
      apiPost({
        url: APIs.PAYMENT,
        data: {
          action: "info.get",
          mer_id: this.$route.params.id,
        },
        success(res) {
          if (res.code === "0") {
            $this.form = res.data;
            $this.form.action = "update.info";
            $this.certList = res.data.cert_list;
            $this.tableData = res.data.reg_list;
            $this.mer_id = $this.$route.params.id;
            // $this.setAlert({ msg: res.msg, type: true });
            // router.push(URL.PAYMENT);
          }
        },
        error(res) {
          $this.setAlert({ msg: "获取数据失败" });
        },
      });
    },
    handleRemove() {},
    handleSuccess(res) {
      if (res.code == 0) {
        console.log(res);
        this.getPaymentInfo();
        // const { data } = res;
        // const image = [data].map((c) => ({
        //   url: "http://" + c.domain + "/" + c.path,
        // }));
        // console.log(image);
        // this.imageList = [...this.imageList, ...image];
        // console.log(this.imageList);
      } else {
        this.$message.error(res.msg);
      }
    },
    // onSubmit() {
    //   let $this = this;
    //   apiPost({
    //     url: APIs.PAYMENT,
    //     data: $this.form,
    //     success(res) {
    //       if (res.code === "0") {
    //         $this.setAlert({ msg: res.msg, type: true });
    //         router.push(URL.PAYMENT);
    //       }
    //     },
    //     error(res) {
    //       $this.setAlert({ msg: "获取数据失败" });
    //     },
    //   });
    // },
    getRowClass({ row, column, rowIndex, columnIndex }) {
      if (rowIndex === 0) {
        return "background-color: #e9f2f1;font-size:12px;color:#525e6e;border:0;font-weight:normal";
      } else {
        return "";
      }
    },
    goBack() {
      //   router.push(URL.EquipmentUpgrade);
    },
    open(val) {
      window.open(val);
    },
    register(val) {
      if (val == "601") {
        let $this = this;
        apiPost({
          url: APIs.ABC,
          data: {
            action: "reg",
            mer_id: $this.mer_id,
          },
          success(res) {
            if (res.code === "0") {
              $this.setAlert({ msg: res.msg, type: true });
              $this.getPaymentInfo();
            } else {
              $this.setAlert({ msg: res.msg });
            }
          },
          error(res) {
            $this.setAlert({ msg: "获取数据失败" });
          },
        });
      }
    },
    verify() {
      this.verifyDialog = true;
    },
    sendCode() {
      let $this = this;
      apiPost({
        url: APIs.ABC,
        data: {
          action: "send.verify.message",
          mer_id: $this.mer_id,
        },
        success(res) {
          if (res.code === "0") {
            $this.setAlert({ msg: res.msg, type: true });
            $this.getPaymentInfo();
          } else {
            $this.setAlert({ msg: res.msg });
          }
        },
        error(res) {
          $this.setAlert({ msg: "获取数据失败" });
        },
      });
    },
    confirmVerify() {
      this.verifyForm.mer_id = this.mer_id;
      this.verifyForm.action = "info.audit";
      let $this = this;
      apiPost({
        url: APIs.ABC,
        data: this.verifyForm,
        success(res) {
          if (res.code === "0") {
            $this.setAlert({ msg: res.msg, type: true });
            $this.verifyDialog = false;
            $this.verifyForm = {};
            $this.getPaymentInfo();
          } else {
            $this.setAlert({ msg: res.msg });
          }
        },
        error(res) {
          $this.setAlert({ msg: "获取数据失败" });
        },
      });
    },
    upload() {
      // this.verifyForm.mer_id = this.mer_id;
      let $this = this;
      apiPost({
        url: APIs.ABC,
        data: {
          action: "upload.cert",
          mer_id: $this.mer_id,
          flag: $this.certList.map((item) => item.flag),
        },
        success(res) {
          if (res.code === "0") {
            $this.setAlert({ msg: res.msg, type: true });
            $this.getPaymentInfo();
          } else {
            $this.setAlert({ msg: res.msg });
          }
        },
        error(res) {
          $this.setAlert({ msg: "获取数据失败" });
        },
      });
    },
    query() {
      let $this = this;
      apiPost({
        url: APIs.ABC,
        data: {
          action: "query.review.status",
          mer_id: $this.mer_id,
        },
        success(res) {
          if (res.code === "0") {
            $this.setAlert({ msg: res.msg, type: true });
            $this.getPaymentInfo();
          } else {
            $this.setAlert({ msg: res.msg });
          }
        },
        error(res) {
          $this.setAlert({ msg: "获取数据失败" });
        },
      });
    },
    groupChange(val) {
      if (
        this.accountData.length > 0 &&
        this.accountData.length == this.mer_arr.length
      ) {
        const arr = this.mer_arr.filter((c) => val.includes(c.mer_id));
        const new_arr = arr.map((item) => item.mer_id);
        this.accountData = this.accountData.filter((c) =>
          new_arr.includes(c.mer_id)
        );
      } else {
        const arr = this.mer_arr.filter((c) => val.includes(c.mer_id));
        this.accountData = arr.map((item) => {
          return {
            ...item,
            mode_id: 601,
          };
        });
      }
    },

    //获取分账商户列表
    getMerchList() {
      let $this = this;
      apiPost({
        url: APIs.ABC,
        data: {
          action: "reg.list",
          start: 0,
          length: 100,
        },
        success(res) {
          if (res.code === "0") {
            $this.mer_arr = res.data;
            console.log($this.accountData);
          } else {
            $this.setAlert({ msg: res.msg });
          }
        },
        error(res) {
          $this.setAlert({ msg: "获取数据失败" });
        },
      });
    },
    createAccount() {
      this.accountDialog = true;
      this.getMerchList();
    },
    // 获取分账分组列表
    getDetailList() {
      let $this = this;
      apiPost({
        url: APIs.ABC,
        data: {
          action: "split.group.list",
          start: 0,
          length: 100,
        },
        success(res) {
          if (res.code === "0") {
            $this.accountData1 = res.data.map((item) => {
              return {
                ...item,
                mode_id: 601,
              };
            });
            console.log($this.accountData1);
          } else {
            $this.setAlert({ msg: res.msg });
          }
        },
        error(res) {
          $this.setAlert({ msg: "获取数据失败" });
        },
      });
    },
    detailAccount() {
      this.verifyDialog1 = true;
      this.getDetailList();
    },
    edit(val) {
      this.getMerchList();
      // console.log(this.mer_arr);
      console.log(JSON.parse(val.account_items));
      this.group_id = val.id;
      this.innerDrawer = true;
      // group_mer_id
      this.group_name = val.group_name;
      this.accountData = JSON.parse(val.account_items).map((item, index) => {
        return {
          ...item,
          mode_id: 601,
        };
      });
      console.log(this.accountData.map((c) => c.mer_id));
      this.group_mer_id = this.accountData.map((c) => c.mer_id);
    },
    confirmAccount() {
      let $this = this;
      apiPost({
        url: APIs.ABC,
        data: {
          action: "split.group.apply",
          group_name: $this.group_name,
          account_items: JSON.stringify(
            $this.accountData.map((item) => {
              return {
                reg_id: item.id,
                mer_id: item.mer_id,
                mer_no: item.mer_no,
                split_ratio: item.split_ratio,
              };
            })
          ),
        },
        success(res) {
          if (res.code === "0") {
            $this.setAlert({ msg: res.msg, type: true });
            $this.getPaymentInfo();
            $this.accountDialog = false;
          } else {
            $this.setAlert({ msg: res.msg });
          }
        },
        error(res) {
          $this.setAlert({ msg: "获取数据失败" });
        },
      });
    },
    updateAccount() {
      console.log(this.accountData);
      let $this = this;
      apiPost({
        url: APIs.ABC,
        data: {
          id: $this.group_id,
          action: "split.group.apply",
          group_name: $this.group_name,
          account_items: JSON.stringify(
            $this.accountData.map((item) => {
              return {
                reg_id: item.reg_id ? item.reg_id : item.id,
                mer_no: item.mer_no,
                mer_id: item.mer_id,
                split_ratio: item.split_ratio,
              };
            })
          ),
        },
        success(res) {
          if (res.code === "0") {
            $this.setAlert({ msg: res.msg, type: true });
            $this.innerDrawer = false;
            $this.getDetailList();
            $this.getPaymentInfo();
          } else {
            $this.setAlert({ msg: res.msg });
          }
        },
        error(res) {
          $this.setAlert({ msg: "获取数据失败" });
        },
      });
    },
    close() {
      this.accountDialog = false;
      this.group_name = "";
      this.verifyForm = {};
      this.group_mer_id = [];
      // this.accountData = ;
      this.accountData = [];
    },
  },
};
</script>

<style lang="less">
.payreg_add {
  background: #fff;
  min-height: 100vh;
  box-shadow: 0 0 10px 0 #e0e3e8;
  margin-left: 18px;
  margin-right: 18px;
  margin-bottom: 18px;
  border-radius: 4px;
  padding: 24px;

  .cert {
    margin-bottom: 20px;
    .list {
      display: flex;
      .el-card {
        width: 600px;
        margin-right: 20px;
        .el-card__header {
          font-size: 14px;
          color: #606266;
        }
      }
    }
  }
  .title {
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #4c4d55;
    margin-bottom: 10px;
  }

  // .confirnBtn {
  //   .el-button--primary {
  //     background: #257873 !important;
  //     border-color: #257873 !important;
  //   }
  // }

  .el-drawer__open .el-drawer.rtl {
    padding: 24px;
  }
}

// .drawer {
//   .el-button--mini,
//   .el-button--mini.is-round {
//     background: #fff !important;
//     border: 0 !important;
//     color: #257873 !important;
//   }
// }
// .el-input--small .el-input__inner:focus {
//   border-color: #257873;
// }
</style>
